var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-dialog',{attrs:{"width":"800","persistent":""},model:{value:(_vm.$store.state.dialog),callback:function ($$v) {_vm.$set(_vm.$store.state, "dialog", $$v)},expression:"$store.state.dialog"}},[_c('v-card',{staticClass:"card",attrs:{"flat":""}},[_c('v-toolbar',{attrs:{"outlined":"","flat":"","color":"grey lighten-4"}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$store.state.itemDetails.dialogType == "add" ? _vm.$t("idhafh") : _vm.$t("edit"))+" "+_vm._s(this.$route.meta.single))]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.close()}}},[_c('v-icon',[_vm._v("close")])],1)],1),_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.submit()}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-container',[_c('v-card',{attrs:{"outlined":""}},[_c('v-container',[(_vm.$store.state.itemDetails != '' && _vm.items.length > 0)?_c('v-row',_vm._l((_vm.$store.state.itemDetails.field
                    .fieldChildren),function(item,index){return _c('v-col',{key:index,attrs:{"cols":"6"}},[(item.field.fieldType.name == 'Multiple select')?_c('div',[_c('h4',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(item.field.title)+" "),(item.isRequired)?_c('span',{staticClass:"required"},[_vm._v("*")]):_vm._e()]),_c('select-field',{attrs:{"title":item.field.title,"items":item.field.value.split(','),"isRequired":item.isRequired,"multiple":true,"oldData":_vm.items[index].data},model:{value:(_vm.items[index].data),callback:function ($$v) {_vm.$set(_vm.items[index], "data", $$v)},expression:"items[index].data"}})],1):(item.field.fieldType.name == 'Single select')?_c('div',[_c('h4',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(item.field.title)+" "),(item.isRequired)?_c('span',{staticClass:"required"},[_vm._v("*")]):_vm._e()]),_c('select-field',{attrs:{"title":item.field.title,"items":item.field.value.split(','),"isRequired":_vm.field.isRequired,"multiple":false,"oldData":_vm.items[index].data.split(',')},model:{value:(_vm.items[index].data),callback:function ($$v) {_vm.$set(_vm.items[index], "data", $$v)},expression:"items[index].data"}})],1):(item.field.fieldType.name == 'Check box')?_c('div',[_c('h4',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(item.field.title)+" "),(item.isRequired)?_c('span',{staticClass:"required"},[_vm._v("*")]):_vm._e()]),_c('div',{staticClass:"d-flex"},_vm._l((item.field.value.split(',')),function(check){return _c('v-checkbox',{key:check,staticClass:"mx-1",attrs:{"rules":item.isRequired == true
                            ? [_vm.$global.state.required()]
                            : [],"label":check},on:{"change":(e) => {
                            let arr = _vm.items[index].data.split(',');
                            if (e) {
                              arr.push(check);
                            } else {
                              arr.splice(arr.indexOf(check), 1);
                            }
                            _vm.items[index].data =
                              arr.length == 1 ? check : arr.join(',');
                            if (_vm.items[index].data[0] == ',')
                              _vm.items[index].data = _vm.items[index].data.substr(1);
                            _vm.log(_vm.items[index].data);
                          }}})}),1)]):(item.field.fieldType.name == 'Date and time')?_c('div',[_c('h4',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(item.field.title)+" "),(item.isRequired)?_c('span',{staticClass:"required"},[_vm._v("*")]):_vm._e()]),_c('datefield',{attrs:{"rules":item.isRequired == true
                          ? [_vm.$global.state.required()]
                          : []},model:{value:(_vm.items[index].data),callback:function ($$v) {_vm.$set(_vm.items[index], "data", $$v)},expression:"items[index].data"}})],1):_c('div',[_c('div',{class:item.field.fieldType.name == 'Check box attend'
                          ? 'd-none'
                          : ''},[_c('h4',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(item.field.title)+" "),(item.isRequired)?_c('span',{staticClass:"required"},[_vm._v("*")]):_vm._e()]),_c('v-text-field',{attrs:{"rules":item.isRequired == true
                            ? [_vm.$global.state.required()]
                            : [],"placeholder":item.title,"filled":"","outlined":"","hide-details":"auto","required":""},model:{value:(_vm.items[index].data),callback:function ($$v) {_vm.$set(_vm.items[index], "data", $$v)},expression:"items[index].data"}})],1)])])}),1):_vm._e()],1)],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-row',{staticClass:"mt-2"},[_c('v-btn',{staticClass:"btn",attrs:{"disabled":!_vm.valid,"type":"submit","loading":_vm.$global.state.loading,"x-large":"","color":"primary"}},[_vm._v(_vm._s(_vm.$t("save")))]),_c('v-btn',{staticClass:"mr-2 btn",attrs:{"x-large":"","color":"error","outlined":""},on:{"click":function($event){return _vm.close()}}},[_vm._v(_vm._s(_vm.$t("cancel")))])],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }