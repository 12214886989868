<template>
  <div>
    <v-dialog v-model="$store.state.dialog" width="800" persistent>
      <v-card flat class="card">
        <v-toolbar outlined flat color="grey lighten-4">
          <v-toolbar-title
            >{{
              $store.state.itemDetails.dialogType == "add"
                ? $t("idhafh")
                : $t("edit")
            }}
            {{ this.$route.meta.single }}</v-toolbar-title
          >
          <v-spacer />
          <v-btn @click="close()" icon>
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-form @submit.prevent="submit()" ref="form" v-model="valid">
          <v-container>
            <v-card outlined>
              <v-container>
                <v-row
                  v-if="$store.state.itemDetails != '' && items.length > 0"
                >
                  <v-col
                    v-for="(item, index) in $store.state.itemDetails.field
                      .fieldChildren"
                    :key="index"
                    cols="6"
                  >
                    <!-- Multiple select start -->
                    <div v-if="item.field.fieldType.name == 'Multiple select'">
                      <h4 class="mb-2">
                        {{ item.field.title }}
                        <span class="required" v-if="item.isRequired">*</span> 
                      </h4>
                      <select-field
                        :title="item.field.title"
                        :items="item.field.value.split(',')"
                        v-model="items[index].data"
                        :isRequired="item.isRequired"
                        :multiple="true"
                        :oldData="items[index].data"
                      />
                    </div>
                    <!-- Multiple select end -->
                    <!-- Single select start -->
                    <div
                      v-else-if="item.field.fieldType.name == 'Single select'"
                    >
                      <h4 class="mb-2">
                        {{ item.field.title }}
                        <span class="required" v-if="item.isRequired">*</span>
                      </h4>
                      <select-field
                        :title="item.field.title"
                        :items="item.field.value.split(',')"
                        v-model="items[index].data"
                        :isRequired="field.isRequired"
                        :multiple="false"
                        :oldData="items[index].data.split(',')"
                      />
                    </div>
                    <!-- Single select end -->
                    <!-- Check box start -->
                    <div v-else-if="item.field.fieldType.name == 'Check box'">
                      <h4 class="mb-2">
                        {{ item.field.title }}
                        <span class="required" v-if="item.isRequired">*</span>
                      </h4>
                      <div class="d-flex">
                        <v-checkbox
                          v-for="check in item.field.value.split(',')"
                          :rules="
                            item.isRequired == true
                              ? [$global.state.required()]
                              : []
                          "
                          :key="check"
                          :label="check"
                          class="mx-1"
                          @change="
                            (e) => {
                              let arr = items[index].data.split(',');
                              if (e) {
                                arr.push(check);
                              } else {
                                arr.splice(arr.indexOf(check), 1);
                              }
                              items[index].data =
                                arr.length == 1 ? check : arr.join(',');
                              if (items[index].data[0] == ',')
                                items[index].data = items[index].data.substr(1);
                              log(items[index].data);
                            }
                          "
                        />
                      </div>
                    </div>
                    <!-- Check box end -->
                    <!-- Date and time start -->
                    <div
                      v-else-if="item.field.fieldType.name == 'Date and time'"
                    >
                      <h4 class="mb-2">
                        {{ item.field.title }}
                        <span class="required" v-if="item.isRequired">*</span>
                      </h4>
                      <datefield
                        v-model="items[index].data"
                        :rules="
                          item.isRequired == true
                            ? [$global.state.required()]
                            : []
                        "
                      />
                    </div>
                    <!-- Date and time end -->
                    <div v-else>
                      <div
                        :class="
                          item.field.fieldType.name == 'Check box attend'
                            ? 'd-none'
                            : ''
                        "
                      >
                        <h4 class="mb-2">
                          {{ item.field.title }}
                          <span class="required" v-if="item.isRequired">*</span>
                        </h4>
                        <v-text-field
                          v-model="items[index].data"
                          :rules="
                            item.isRequired == true
                              ? [$global.state.required()]
                              : []
                          "
                          :placeholder="item.title"
                          filled
                          outlined
                          hide-details="auto"
                          required
                        />
                      </div>
                    </div>
                    <!-- text box end -->
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
            <v-col cols="12" md="12">
              <v-row class="mt-2">
                <v-btn 
                  :disabled="!valid"
                  type="submit"
                  :loading="$global.state.loading"
                  x-large
                  color="primary"
                  class="btn"
                  >{{ $t("save") }}</v-btn
                >
                <v-btn
                  @click="close()"
                  x-large
                  color="error"
                  class="mr-2 btn"
                  outlined
                  >{{ $t("cancel") }}</v-btn
                >
              </v-row>
            </v-col>
          </v-container>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import datefield from "../../../../components/fields/datefield.vue";
import SelectField from "../../../../components/fields/SelectField.vue";
export default {
  components: {
    datefield,
    SelectField,
  },
  data() {
    return {
      items: [],
      valid: true,
      test: [],
    };
  },

  mounted() {
    this.reset();
    this.$eventBus.$on("fill-fields", (value) => {
      // console.log(this.$store.state.itemDetails)
      this.$store.state.itemDetails.field.fieldChildren.sort(
        (a, b) => a.sequence - b.sequence
      );
      this.reset();

      if (this.$store.state.itemDetails.dialogType == "add") {
        for (
          let i = 0;
          i < this.$store.state.itemDetails.field.fieldChildren.length;
          i++
        ) {
          this.items.push({
            data:
              this.$store.state.itemDetails.field.fieldType.name ==
              "Multiple select"
                ? []
                : "",
            sequence:
              this.$store.state.itemDetails.field.fieldChildren[i].sequence,
            row: i,
            fieldChildId:
              this.$store.state.itemDetails.field.fieldChildren[i].id,
            fieldId: this.$store.state.itemDetails.field.id,
          });
        }
        // this.items.sort((a, b) => a.sequence - b.sequence);
      }
      if (this.$store.state.itemDetails.dialogType == "edit") {
        this.items = [];
        this.$store.state.itemDetails.field.fieldChildren.forEach((item) => {
          console.log("val", item.field.fieldType.name);
          if (item != "row") {
            this.items.push({
              data:
                item.field.fieldType.name ==
                "Multiple select"
                  ? value[item.id].split(",")
                  : value[item.id],

              sequence: this.$store.state.itemDetails.sequence,
              row: this.$store.state.itemDetails.row,
              fieldChildId: item.id,
              fieldId: this.$store.state.itemDetails.field.id,
            });
            this.$forceUpdate();
          }
        });
      }
      // this.$store.state.itemDetails.field.fieldChildren.sort((a, b) => a.sequence - b.sequence)
    });
  },

  methods: {
    reset() {
      this.items = [];
      // this.valid = false;
    },

    submit() {
      if (this.$store.state.itemDetails.dialogType == "add") {
        this.addItem();
      } else {
        this.editItem();
      }
    },

    async addItem() {
      console.log(this.$refs.form.validate());
      await this.$eventBus.$emit("save-item", this.items);
      // if (this.$store.state.itemDetails.dialogType == "edit") {
      //   await this.$eventBus.$emit("edit-item", this.items);
      // }
      this.close();
    },

    async editItem() {

      await this.$eventBus.$emit("edit-item", {
        row: this.$store.state.itemDetails.row,
        items: this.items,
      });
      this.close();
    },

    close() {
      this.$store.commit("setItemDetails", {});
      this.$store.commit("setDialog");
      this.reset();
      this.resetValidation();
    },

    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },
};
</script>
